<template>
  <ListPage :gridData="data" :gridConfig="gridConfig" :filters="filters" />
</template>

<script>
import ListPage from "@/components/penal-retail-helpers/ListPage";
import { db } from "@/firebase/firebaseConfig";

import vehiclesFormConfig from "@/helpers/vehiclesFormConfig";

let collectionName = "vehicles";

export default {
  components: {
    ListPage,
  },
  data() {
    let attributes = vehiclesFormConfig;

    let columns = [];
    ["plate", "brand", "model", "color"].forEach((a) => {
      columns.push({
        headerName: this.$t(`${collectionName}.columns.${a}`),
        filter: false,
        width: 100,
        field: a,
      });
    });

    let filters = [];
    filters.push({
      size: "1/5",
      mobileSize: "1/2",
      extraClasses: "mb-5",
      search: true,
    });
    if (
      ["supercustomer", "customer"].includes(
        this.$store.state.AppActiveUser.role
      )
    ) {
      filters.push({
        size: "4/5",
        mobileSize: "1/2",
        extraClasses: "mb-5",
      });
    } else {
      filters.push({
        size: "4/5",
        mobileSize: "1/2",
        sidebar: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        sidebarConfig: {
          title: `${collectionName}.buttons.new`,
          collectionName: collectionName,
          attributes: attributes,
          prevalidate: async (data, collection, errors) => {
            let snapshot = await collection
              .where("plate", "==", data.plate)
              .get();

            if (snapshot.docs && snapshot.docs.length > 0) {
              errors.add({
                field: "plate",
                msg: this.$t(`${collectionName}.validations.plate.unique`),
              });
              return true;
            }
            if (!/^[0-9]{4}[A-Z]{3}$/.test(data.plate)) {
              errors.add({
                field: "plate",
                msg: this.$t(`${collectionName}.validations.plate.invalid`),
              });
              return true;
            }

            return false;
          },
        },
      });
    }

    return {
      data: [],
      filters,
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  created() {
    this.$bind(
      "data",
      db
        .collection(collectionName)
        .where("deleted", "==", false)
        .orderBy("plate"),
      { wait: true }
    );
  },
  methods: {
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
